'use client';

import { ReactNode } from 'react';
import { cn } from '@/service/utils/cn';
import { IconButton } from '../Buttons/IconButton';
import { CloseIcon } from '../Icons/CloseIcon';

type TModalProps = {
    popupClassName?: string;
    closerClassName?: string;
    closeButtonClassName?: string;
    isOpen: boolean;
    id?: string;
    closeModal?: () => void;
    children?: ReactNode;
    useCloseBtnOnly?: boolean;
};

export const Modal = ({
    popupClassName,
    closerClassName,
    closeButtonClassName,
    isOpen,
    id,
    closeModal,
    children,
}: TModalProps) => (
    <div
        className={`fixed left-0 top-0 z-[60] h-screen w-screen bg-black-1 ${
            isOpen ? 'block' : 'hidden'
        }`}
        onClick={closeModal}
        id={id || ''}
    >
        <div
            className={cn(
                'absolute left-1/2 top-1/2 z-50 h-max w-full max-w-[340px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[15px] border-none bg-white px-[30px] pb-[30px] pt-5 dark:bg-dark-3 md:max-w-[550px] md:p-[30px] lg:max-w-[659px] lg:p-[45px]',
                popupClassName
            )}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {!!closeModal && (
                <IconButton
                    icon={<CloseIcon className={closerClassName} />}
                    className={cn('absolute right-3 top-3 rounded-full', closeButtonClassName)}
                    onClick={closeModal}
                />
            )}
            {children}
        </div>
    </div>
);
