import { Modal } from '@/ui/common/Modal/Modal';
import { LinkIcon } from '@/ui/common/Links/LinkIcon';
import { UfoIcon } from '@/ui/common/Icons/UfoIcon';
import { SocketUnplugged } from '@/ui/common/Icons/SocketUnplugged';
import { ErrorButton } from './ErrorButton';

type TErrorContent = {
    icon: JSX.Element;
    title: string;
    content: string[];
    bgImageClass: string;
};
type TErrorContentData = {
    [key: string]: TErrorContent;
};

export const ErrorContent = ({
    errorCode,
    errorHandler,
    errorContent,
}: {
    errorCode: string;
    errorContent?: TErrorContent;
    errorHandler?: () => void;
}) => {
    const type = errorCode || '500';

    const errorContentData: TErrorContentData = {
        '404': {
            icon: <UfoIcon className="mx-auto mb-7" />,
            title: 'Something went wrong...',
            content: [
                'We’re sorry. The resource you are looking for cannot be found.',
                'Please choose an option below.',
            ],
            bgImageClass: 'bg-error-404',
        },
        '500': {
            icon: <SocketUnplugged className="mx-auto mb-7" />,
            title: '500 server error. Looks like you broke the Internet. Just kidding...',
            content: [
                'We will get this fixed ASAP. The team has already been notified.',
                'Thanks for your patience.',
            ],
            bgImageClass: 'bg-error-500',
        },
        ...(typeof errorContent !== 'undefined' && { [errorCode]: errorContent }),
    };

    const { icon, title, content, bgImageClass } = errorContentData[type];

    const errContent = content.map((i, idx) => (
        <h6 key={`error-content-${idx}`} className="tx-large-regular text-gray-3 dark:text-dark-1">
            {i}
        </h6>
    ));

    return (
        <div className={`relative min-h-screen w-full px-4 py-10 md:px-8`}>
            <div
                className={`absolute inset-0  bg-cover bg-center bg-no-repeat blur ${
                    bgImageClass || 'bg-error-500'
                }`}
            ></div>

            <Modal isOpen={true}>
                <div className="mx-auto max-w-[520px] text-center">
                    <LinkIcon
                        iconSrc="/WaverezLogoGrey.svg"
                        href="https://www.waverez.com"
                        className="inline-block w-[127px] md:w-[183px]"
                        alt="WaverezLogoGrey"
                    />

                    <div className="mb-7 mt-2 h-0 w-full max-w-full border-[0.5px] border-gray-4 dark:border-dark-1 md:mt-5"></div>

                    {icon ? icon : <SocketUnplugged className="mx-auto mb-7" />}

                    <h1 className="tx-large-medium mb-3 text-gray-1 dark:text-white">{title}</h1>

                    {errContent}
                    <ErrorButton errorHandler={errorHandler} />
                </div>
            </Modal>
        </div>
    );
};
